.fixed {
    position: fixed
}
.absolute {
    position: absolute
}
.relative {
    position: relative
}
.sticky {
    position: sticky
}
.bottom-0 {
    bottom: 0px
}
.bottom-\[-2px\] {
    bottom: -2px
}
.left-0 {
    left: 0px
}
.left-1 {
    left: 0.25rem
}
.left-4 {
    left: 1rem
}
.right-0 {
    right: 0px
}
.right-4 {
    right: 1rem
}
.right-\[-3px\] {
    right: -3px
}
.top-0 {
    top: 0px
}
.top-2 {
    top: 0.5rem
}
.top-4 {
    top: 1rem
}
.-z-10 {
    z-index: -10
}
.z-10 {
    z-index: 10
}
.z-20 {
    z-index: 20
}
.z-50 {
    z-index: 50
}
.mb-4 {
    margin-bottom: 1rem
}
.ml-0 {
    margin-left: 0px
}
.ml-2 {
    margin-left: 0.5rem
}
.ml-\[240px\] {
    margin-left: 240px
}
.mt-12 {
    margin-top: 3rem
}
.flex {
    display: flex
}
.h-16 {
    height: 4rem
}
.h-4 {
    height: 1rem
}
.h-6 {
    height: 1.5rem
}
.h-8 {
    height: 2rem
}
.h-screen {
    height: 100vh
}
.w-4 {
    width: 1rem
}
.w-\[240px\] {
    width: 240px
}
.w-full {
    width: 100%
}
.max-w-\[360px\] {
    max-width: 360px
}
.flex-1 {
    flex: 1 1 0%
}
.flex-col {
    flex-direction: column
}
.items-center {
    align-items: center
}
.justify-center {
    justify-content: center
}
.justify-between {
    justify-content: space-between
}
.gap-2 {
    gap: 0.5rem
}
.gap-4 {
    gap: 1rem
}
.overflow-y-auto {
    overflow-y: auto
}
.overflow-x-hidden {
    overflow-x: hidden
}
.rounded-full {
    border-radius: 9999px
}
.border-\[1px\] {
    border-width: 1px
}
.border-default {
    --tw-border-opacity: 1;
    border-color: rgb(217 217 217 / var(--tw-border-opacity, 1))
}
.\!bg-body-background {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(243 243 243 / var(--tw-bg-opacity, 1)) !important
}
.bg-black\/70 {
    background-color: rgb(0 0 0 / 0.7)
}
.bg-secondary {
    --tw-bg-opacity: 1;
    background-color: rgb(225 251 50 / var(--tw-bg-opacity, 1))
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1))
}
.p-0 {
    padding: 0px
}
.p-5 {
    padding: 1.25rem
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}
.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}
.pl-20 {
    padding-left: 5rem
}
.pr-0 {
    padding-right: 0px
}
.pt-10 {
    padding-top: 2.5rem
}
.text-center {
    text-align: center
}
.text-\[10px\] {
    font-size: 10px
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem
}
.tracking-wider {
    letter-spacing: 0.05em
}
.text-\[\#555\] {
    --tw-text-opacity: 1;
    color: rgb(85 85 85 / var(--tw-text-opacity, 1))
}
.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1))
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1))
}
.opacity-0 {
    opacity: 0
}
.opacity-100 {
    opacity: 1
}
.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.duration-1000 {
    transition-duration: 1000ms
}
.duration-\[250ms\] {
    transition-duration: 250ms
}
.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
@media (min-width: 640px) {
    .sm\:sticky {
        position: sticky
    }
    .sm\:bottom-0 {
        bottom: 0px
    }
    .sm\:top-0 {
        top: 0px
    }
    .sm\:hidden {
        display: none
    }
    .sm\:w-0 {
        width: 0px
    }
    .sm\:gap-6 {
        gap: 1.5rem
    }
}
@media (min-width: 768px) {
    .md\:ml-0 {
        margin-left: 0px
    }
    .md\:w-\[60px\] {
        width: 60px
    }
}
