/* temp_stylePlugin:src/css/flow-node.css */
.flow-node {
  pointer-events: auto;
  width: max-content;
  font-size: 14px;
}
.flow-node .flow-node-bg {
  position: absolute;
  inset: 0px;
  display: flex;
  align-items: center;
}
.flow-node .flow-node-bg .flow-node-outline {
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  z-index: -10;
  border-width: 3px;
  border-color: transparent;
}
.flow-node:hover .flow-node-outline {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}
.flow-node.flow-node-selected .flow-node-outline {
  --tw-border-opacity: 1;
  border-color: rgb(50 146 250 / var(--tw-border-opacity, 1));
}
.flow-node textarea {
  padding-bottom: 0;
  border: none;
  outline: none;
}
.flow-node .react-flow__handle {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.375rem;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(39 52 68 / var(--tw-bg-opacity, 1));
}
.flow-node .react-flow__node-flowNodeDnd {
  z-index: -10;
}
.react-flow__viewport .react-flow__node {
  pointer-events: none !important;
}
.flow-node span {
  display: block;
  width: 100%;
  cursor: text;
  text-align: center;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

/* temp_stylePlugin:src/css/flow.css */
.flow .react-flow__pane .react-flow__edge .react-flow__edge-path {
  stroke-width: 3px;
}

/*# sourceMappingURL=../../packages/flow/dist/index.css.map */