/* temp_stylePlugin:src/main.css */
*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.visible {
  visibility: visible;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.bottom-0 {
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
.right-0 {
  right: 0px;
}
.right-4 {
  right: 1rem;
}
.right-\[-4px\] {
  right: -4px;
}
.top-0 {
  top: 0px;
}
.top-4 {
  top: 1rem;
}
.top-\[-4px\] {
  top: -4px;
}
.z-20 {
  z-index: 20;
}
.m-auto {
  margin: auto;
}
.\!mb-0 {
  margin-bottom: 0px !important;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-auto {
  margin-top: auto;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.\!hidden {
  display: none !important;
}
.hidden {
  display: none;
}
.h-10 {
  height: 2.5rem;
}
.h-12 {
  height: 3rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-28 {
  height: 7rem;
}
.h-4 {
  height: 1rem;
}
.h-40 {
  height: 10rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-7 {
  height: 1.75rem;
}
.h-8 {
  height: 2rem;
}
.h-80 {
  height: 20rem;
}
.h-9 {
  height: 2.25rem;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.w-12 {
  width: 3rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-28 {
  width: 7rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-60 {
  width: 15rem;
}
.w-7 {
  width: 1.75rem;
}
.w-8 {
  width: 2rem;
}
.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}
.w-full {
  width: 100%;
}
.w-max {
  width: -moz-max-content;
  width: max-content;
}
.max-w-3xl {
  max-width: 48rem;
}
.max-w-\[150px\] {
  max-width: 150px;
}
.max-w-lg {
  max-width: 32rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-\[0\.2\] {
  flex: 0.2;
}
.flex-\[0\.3\] {
  flex: 0.3;
}
.flex-\[0\.5\] {
  flex: 0.5;
}
.shrink-0 {
  flex-shrink: 0;
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.animate-\[200ms\] {
  animation: 200ms;
}
.cursor-pointer {
  cursor: pointer;
}
.resize {
  resize: both;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-10 {
  gap: 2.5rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-md {
  border-radius: 0.375rem;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-\[1px\] {
  border-width: 1px;
}
.border-\[2px\] {
  border-width: 2px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.\!border-primary {
  --tw-border-opacity: 1 !important;
  border-color: rgb(119 128 130 / var(--tw-border-opacity, 1)) !important;
}
.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}
.border-default {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.\!bg-body-background {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 243 243 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-default {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1)) !important;
}
.bg-\[\#0005\] {
  background-color: #0005;
}
.bg-\[\#d9d9d9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}
.bg-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(228 38 14 / var(--tw-bg-opacity, 1));
}
.bg-default {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(119 128 130 / var(--tw-bg-opacity, 1));
}
.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(225 251 50 / var(--tw-bg-opacity, 1));
}
.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(51 119 66 / var(--tw-bg-opacity, 1));
}
.bg-transparent {
  background-color: transparent;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.p-0 {
  padding: 0px;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.\!py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pl-0 {
  padding-left: 0px;
}
.pl-1 {
  padding-left: 0.25rem;
}
.text-center {
  text-align: center;
}
.text-\[12px\] {
  font-size: 12px;
}
.text-\[14px\] {
  font-size: 14px;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.text-danger {
  --tw-text-opacity: 1;
  color: rgb(228 38 14 / var(--tw-text-opacity, 1));
}
.text-default {
  --tw-text-opacity: 1;
  color: rgb(217 217 217 / var(--tw-text-opacity, 1));
}
.text-gray {
  --tw-text-opacity: 1;
  color: rgb(85 85 85 / var(--tw-text-opacity, 1));
}
.text-link {
  --tw-text-opacity: 1;
  color: rgb(50 146 250 / var(--tw-text-opacity, 1));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.\!shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow) !important;
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
}
.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    -webkit-backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.btn-love {
}
.btn-love.active {
  color: var(--danger);
}
.btn-love .animated {
}
.btn-love:hover i,
.btn-order:hover i,
.btn-post:hover i,
.btn-share:hover i,
.btn-send:hover i,
.btn-interested:hover i,
.btn-subscribe:hover i,
.btn-comment:hover i {
  animation-name: heartBeat;
  animation-duration: 1s;
}
.btn.btn-black {
  background-color: var(--black);
  border-color: var(--black);
  color: #fff;
}
.btn.btn-black:hover,
.btn.btn-black:active,
.btn.btn-black:focus,
.btn.btn-black.active {
  background-color: var(--black-hover);
}
.btn.btn-black-opacity {
  background-color: var(--black) 5;
  border: none;
}
.btn-interested.active,
.btn-subscribe.active {
  color: #007bff;
}
.btn-upload-image {
  color: #fff;
}
.btn.btn-semi-transparent {
  opacity: 0.8;
}
.btn.btn-transparent {
  background-color: transparent;
  border: 1px solid transparent;
}
.btn.btn-tiles {
  width: 30px;
  height: 30px;
  margin-top: 0;
  border: 1px solid var(--black-hover);
  padding: 0px;
}
.btn.btn-tiles.btn-tiles-xs {
  width: 15px;
  height: 15px;
}
.control-tag {
  font-size: 14px;
  padding: 5px 15px;
  padding-left: 10px;
  margin: 5px;
  background-color: var(--default);
  border: 1px solid var(--default);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  white-space: nowrap;
  position: relative;
  color: var(--black);
}
.control-tag:hover {
  opacity: 0.8;
  transition: var(--animation-fast);
}
.full-width {
  width: 100% !important;
}
.badge {
  color: #ffffff;
  font-size: 10px;
  padding: 4px 8px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 9999px;
}
.after\:-ml-4::after {
  content: var(--tw-content);
  margin-left: -1rem;
}
.after\:-mr-10::after {
  content: var(--tw-content);
  margin-right: -2.5rem;
}
.after\:mt-3::after {
  content: var(--tw-content);
  margin-top: 0.75rem;
}
.after\:inline-block::after {
  content: var(--tw-content);
  display: inline-block;
}
.after\:h-1::after {
  content: var(--tw-content);
  height: 0.25rem;
}
.after\:w-full::after {
  content: var(--tw-content);
  width: 100%;
}
.after\:border-4::after {
  content: var(--tw-content);
  border-width: 4px;
}
.after\:border-b::after {
  content: var(--tw-content);
  border-bottom-width: 1px;
}
.after\:border-primary::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(119 128 130 / var(--tw-border-opacity, 1));
}
.after\:border-secondary::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(225 251 50 / var(--tw-border-opacity, 1));
}
.after\:content-\[\'\'\]::after {
  --tw-content: "";
  content: var(--tw-content);
}
.focus-within\:outline-none:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-within\:ring-primary:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(119 128 130 / var(--tw-ring-opacity, 1));
}
.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px;
}
.hover\:text-link:hover {
  --tw-text-opacity: 1;
  color: rgb(50 146 250 / var(--tw-text-opacity, 1));
}
.disabled\:opacity-25:disabled {
  opacity: 0.25;
}

/*# sourceMappingURL=../../packages/component/dist/main.css.map */