/* temp_stylePlugin:src/components/box/css/style.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.box {
  padding: 1rem;
  scrollbar-color: var(--black) transparent;
  scrollbar-width: thin;
}
.box h3 {
  font-size: 18px;
}
.box.box-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(39 52 68 / var(--tw-text-opacity, 1));
}
.box.box-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(119 128 130 / var(--tw-bg-opacity, 1));
}
.box.box-default {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}
.box.box-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(85 85 85 / var(--tw-bg-opacity, 1));
}
.box h3 i {
  margin-right: 0.5rem;
}
.box.row {
  margin-left: 0px;
  margin-right: 0px;
}
.box.box-padding-0 {
  padding: 0px;
}
.box::-webkit-scrollbar {
  width: 1rem;
}
.box::-webkit-scrollbar-track {
  background-color: transparent;
}
.box::-webkit-scrollbar-thumb {
  border-width: 6px;
  border-color: transparent;
  background-color: transparent;
  background-clip: content-box;
  -webkit-transition: color .3s ease;
  transition: color .3s ease;
}
.box:hover::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(39 52 68 / var(--tw-bg-opacity, 1));
}
.box::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(85 85 85 / var(--tw-bg-opacity, 1));
}

/* temp_stylePlugin:src/components/button/css/style.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  transition: all 0.2s ease-in-out;
}
.btn.btn-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 12px;
}
.btn.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(119 128 130 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.btn.btn-primary:hover {
  opacity: 0.8;
}
.btn.btn-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(225 251 50 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.btn.btn-secondary:hover {
  opacity: 0.8;
}
.btn.btn-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(228 38 14 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.btn.btn-danger:hover {
  opacity: 0.8;
}
.btn.btn-default {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}
.btn.btn-default:hover {
  opacity: 0.8;
}
.btn:disabled,
.btn.active:disabled,
.btn:hover:disabled {
  cursor: default;
  opacity: 0.4;
}

/* temp_stylePlugin:src/components/tooltip/css/style.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

/* temp_stylePlugin:src/components/dropdown/css/dropdown.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.dropdown {
  z-index: 99999;
}
.dropdown-arrow {
  z-index: -1;
}
.dropdown-arrow::after {
  content: "";
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
  background: var(--white);
  box-shadow: var(--box-shadow);
  position: absolute;
  z-index: -1;
  top: -3px;
  left: calc(50% - 10px);
}
.dropdown-content {
  background: var(--white);
}

/* temp_stylePlugin:src/components/control/css/control.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.control {
  font-size: 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.control label {
  margin-right: 0.5rem;
  margin-left: 0.25rem;
  margin-bottom: 0px;
  white-space: nowrap;
}
.control input,
.control textarea {
  border-radius: 8px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
  background-color: transparent;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 14px;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(39 52 68 / var(--tw-text-opacity, 1));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.control input:focus,
.control textarea:focus {
  --tw-border-opacity: 1;
  border-color: rgb(39 52 68 / var(--tw-border-opacity, 1));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.control .text-message {
  font-size: 12px;
}
.control.readonly {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.control.\!readonly {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.control.control-numeric {
}
.control .control-btn {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 2px;
  padding-bottom: 2px;
}
.control .control-btn i {
  font-size: 10px;
}
[data-radix-popper-content-wrapper] {
  z-index: 9999 !important;
}
.mdxeditor ul {
  margin-left: 15px;
}
.mdxeditor ul li {
  list-style: disc;
}

/* temp_stylePlugin:src/components/chip/style.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.control-chip {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.control-chip a {
}
.control-chip .control-chip-start-icon {
  margin-left: -0.5rem;
}
.control-chip .control-chip-end-icon {
  margin-right: -0.5rem;
  display: flex;
  height: 1rem;
  width: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.control-chip:hover {
  cursor: pointer;
  opacity: 0.9;
}

/* temp_stylePlugin:src/components/menu/css/menu.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.menu {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
}
.menu.menu-horizontal {
  flex-direction: row;
  overflow: auto;
}
.menu.menu-horizontal a {
  width: auto;
  white-space: nowrap;
}
.menu-item a {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  font-size: 14px;
}
.menu-item a .item-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.menu-item a .item-footer {
  display: flex;
  align-items: center;
}
.menu-item a:hover,
.menu-item a:active,
.menu-item.active a {
  border-radius: 0.375rem;
  background-color: rgb(115 115 115 / .2);
}
.menu-item a i {
  width: 1.5rem;
  text-align: center;
  font-size: 14px;
}
.menu-item a .icon-menu img {
  width: 1rem;
}
.menu-item ul {
  margin: 0px;
  padding: 0px;
}
.menu-item .item-badge {
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(228 38 14 / var(--tw-bg-opacity, 1));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

/* temp_stylePlugin:src/components/control/css/control-password.css */
.control-password {
}
.control-password .control-password-wrapper {
  position: relative;
  width: 100%;
  display: flex;
}
.control-password .form-control {
  flex: 1;
}
.control-password .btn-toggle-password {
  position: absolute;
  top: 0;
  right: 0;
}

/* temp_stylePlugin:src/components/control/css/toggle.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.control .switch {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 45px;
  height: 24px;
}
.control .switch input {
  display: none;
}
.control .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ddd;
  transition: .4s;
}
.control .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}
.control .switch.active .slider {
  background-color: #111;
}
.control input:focus + .slider {
  box-shadow: 0 0 1px #555;
}
.control .switch.active .slider:before {
  transform: translateX(13px);
}
.control .slider.round {
  border-radius: 34px;
}
.control .slider.round:before {
  border-radius: 32px;
}

/* temp_stylePlugin:src/components/modal/css/dialog.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.dialog-header {
  display: flex;
  justify-content: space-between;
}
.dialog-mask {
  position: fixed;
  inset: 0px;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 2.5rem;
  padding-bottom: 5rem;
  background-color: #0005;
}
.dialog-body {
  display: flex;
  height: -moz-max-content;
  height: max-content;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.25rem;
  background-color: var(--white);
}

/* temp_stylePlugin:src/components/breadcrumb/css/breadcrumb.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.breadcrumb {
  z-index: 10;
  display: flex;
}
.breadcrumb-item {
  position: relative;
}
.breadcrumb-item:not(:last-child)::after {
  content: " ";
}
.breadcrumb-item:not(:last-child)::before {
  content: " ";
}
.breadcrumb-item:not(:last-child):not(.active):hover:before {
  --tw-border-opacity: 1;
  border-left-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.breadcrumb-item:not(:last-child):not(.active):hover:after {
  --tw-border-opacity: 1;
  border-left-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}
.breadcrumb-item a,
.breadcrumb-item span {
  display: block;
  height: 100%;
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 1.25rem;
  padding-left: 2rem;
  text-align: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.breadcrumb-item:hover a,
.breadcrumb-item a:active {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}
.breadcrumb-item i {
  width: 0.75rem;
  text-align: center;
  font-size: 14px;
}

/* temp_stylePlugin:src/components/control/css/search-form.css */
.search-form {
  position: relative;
}
.search-form .form-control {
  color: var(--black);
}
.search-form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: var(--black);
  z-index: 9;
}

/* temp_stylePlugin:src/components/control/css/style.css */
.control-list {
  width: 100%;
}
.control .control-checkbox {
}
.control .control-checkbox ul {
  padding: 0;
  list-style-type: none;
}
.control .control-checkbox li {
  cursor: pointer;
}
.control .control-checkbox li .checkbox-icon {
  margin: 5px;
  margin-right: 10px;
}
.control .control-checkbox li span {
  font-size: 12px;
  font-family: var(--font-family);
}
.control-date {
}
.control-time .control-suggest,
.control-date .control-suggest {
  min-width: unset;
  width: auto;
}
.control-time .control-time-container,
.control-date .control-date-container {
  display: flex;
  margin-right: 15px;
  margin-bottom: 0;
}
.control-date .control-date-container .form-date {
  width: 60px;
  text-align: right;
  margin-right: 15px;
}
.control-date .control-date-container .form-month {
  margin-right: 15px;
}
.control-date .control-date-container .form-year {
  width: 65px;
  text-align: right;
}
.control-time .control-time-container .form-hour {
  width: 60px;
  margin-right: 15px;
}
.control-time .control-time-container .form-minute {
  width: 60px;
}
.control-image {
  width: 100%;
  position: relative;
  height: auto;
  background-color: var(--default);
}
.control-image img {
  max-width: 320px;
  margin: auto;
  margin-bottom: 10px;
}
.control-image > p {
  margin-top: 15px;
  text-align: center;
  margin-bottom: 0;
}
.form-container .gallery-image {
  position: relative;
  min-height: 640px;
}
.control-table {
  position: relative;
}
.control-table .import-produit-form {
  width: 100%;
  margin-bottom: 15px;
}
.control-table .import-produit-form .form-control-container {
  margin: 0 !important;
}
.control-table table {
  width: 100%;
  overflow: hidden;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid #bbb;
}
.control-table table tr th {
  position: relative;
  font-family: var(--font-family-bold);
  font-weight: normal;
  font-size: 14px;
  padding: 10px;
  background-color: #111;
  border: 1px solid #111;
  color: #fff;
}
.control-table table tr th .form-control {
}
.control-table table tr td:first-child {
  padding-left: 25px;
}
.control-table table tr td {
  position: relative;
  padding: 5px 10px;
  background-color: transparent;
  border: 1px solid #bbb;
  font-size: 12px;
  width: 50%;
}
.control-table table tr .form-control-container {
  margin: 0;
}
.control-table table tr .btn-delete {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: #ff2c56b3;
  border-color: #ff2c56b3;
}
.control-table table tr:hover .btn-delete {
  animation-name: fadeInRight;
  animation-duration: 0.2s;
  opacity: 1;
}
.control-table table tr .btn-sort {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 5px;
  background-color: transparent;
  width: 20px;
  cursor: move;
  opacity: 0;
}
.control-table table tr textarea {
  resize: none;
}
.control-table table tr:hover .btn-sort {
  animation-name: fadeIn;
  animation-duration: 0.2s;
  opacity: 1;
}
.control-table table.drag tr:hover td {
  border-bottom: 2px solid #bbb;
  background-color: #f5f5f5;
}
.control-table table.drag-categorie:hover td {
  background-color: #eee;
}
.control-button {
}
.control-button .tooltip-container {
  margin-left: auto;
}
.control-trackbar {
  font-size: 12px;
}
.control-trackbar .min-value {
}
.control-trackbar .max-value {
  float: right;
}
.control-trackbar .control-rail {
  position: relative;
  background-color: #fff;
  border: 1px solid #bbb;
  height: 7px;
  border-radius: 20px;
  margin-top: 10px;
}
.control-trackbar .control-rail .control-wagon {
  position: absolute;
  top: -10px;
  left: 15%;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 20px;
  padding: 0;
  width: 10px;
  height: 25px;
}
.control-trackbar .control-rail .control-wagon:hover,
.control-trackbar .control-rail .control-wagon:active {
  cursor: pointer;
  background-color: #eee;
}
.control-multiple-text {
}
.multiple-text {
}
.multiple-text .text-item {
  position: relative;
}
.multiple-text .text-item .btn-delete {
  position: absolute;
  top: 0;
  right: 0;
}
.sub-process-item {
  position: relative;
}
.sub-process-item .tooltip-container {
  position: relative;
  top: unset;
  right: unset;
}
.sub-process-item .tooltip-container .btn-cmd {
  position: relative;
  top: unset;
  right: unset;
}
.search-form {
  position: relative;
}
.search-form .form-control {
  color: var(--black);
}
.search-form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: var(--black);
  z-index: 9;
}
.control-color {
}
.control-color button:not(.btn-more-color, .btn-tiles) {
  border: none;
  width: 15px;
  height: 15px;
  margin-top: 0;
  border: 1px solid var(--default);
}
.control-color .dropdown-toggle::after {
  content: none;
}
.pallet-container {
  display: flex;
  flex-direction: column;
  width: 210px;
  padding: 5px;
  background-color: white;
}
.pallet-container p {
  margin-bottom: 2px;
  white-space: nowrap;
  text-align: center;
}
.pallet-container p button.active,
.pallet-container p button:hover {
  border: 1px solid var(--default);
}
.pallet-container p button.btn-more-color {
  float: left;
  width: 100%;
}
.pallet-container p.first {
  margin-bottom: 10px;
}
.control-select-object {
}
.control-select-object .btn-cmd-down.btn-cmd {
  bottom: unset;
  top: 5px;
}
.control-select-object .control-suggest {
  position: relative;
  top: unset;
  left: unset;
  right: unset;
  box-shadow: none;
}
.control-select-object .control-suggest li h3 {
  font-family: var(--font-family-bold);
  font-size: 16px;
  margin-bottom: 0;
}
.control-select-object .control-suggest li p {
  font-family: var(--font-family);
  font-size: 12px;
  margin-bottom: 0;
}
.control-lov-select {
}
.control-lov-select .btn-lovs-setting {
  padding: 5px;
  background-color: transparent;
  position: absolute;
  bottom: 0px;
  right: 30px;
}
.control-image {
}
.control-image .image {
  height: 240px;
  background-color: var(--default);
  display: flex;
  background-size: contain;
}
.control-image .image label {
  margin-top: auto;
  background-color: var(--default-opacity);
  width: 100%;
}
.control-html-editor {
}
.control-html-editor .sun-editor,
.control-html-editor .sun-editor-editable {
  font-family: var(--font-family);
}
.control-html-editor .sun-editor b,
.control-html-editor .sun-editor strong {
  font-family: var(--font-family-bold);
}
.control-html-editor .sun-editor .se-toolbar.se-toolbar-balloon,
.control-html-editor .sun-editor .se-toolbar.se-toolbar-inline {
  box-shadow: var(--box-shadow);
}
.control-html-editor .sun-editor .se-btn-module-border {
  border: none;
}
.control-html-editor .sun-editor .se-svg,
.sun-editor button > svg {
  width: 12px;
  height: 12px;
}
.control-html-editor .sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
  box-shadow: var(--box-shadow);
  border: var(--input-border);
  border-radius: 0;
}
.control-html-editor .sun-editor .se-dialog .se-dialog-inner .se-btn-primary {
  background-color: var(--primary);
  color: var(--white);
  border-radius: var(--border-radius);
  padding: 7px 25px;
}
.control-html-editor .sun-editor .se-dialog .se-dialog-inner .se-btn-primary:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
}

/* temp_stylePlugin:src/components/control/css/select-object.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.control-select-object {
}
.control-select-object .control-select-object-item {
  cursor: pointer;
  border-width: 3px;
  border-color: transparent;
  padding: 0.25rem;
}
.control-select-object .control-select-object-item:hover,
.control-select-object .control-select-object-item.selected {
  --tw-border-opacity: 1;
  border-color: rgb(119 128 130 / var(--tw-border-opacity, 1));
}

/* temp_stylePlugin:src/components/control/css/control-list.css */
.control-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.control-list .control-list-container {
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
}
.control-list .control-list-container .control {
  width: 100%;
}
@media only screen and (min-width: 576px) {
  .control-list .control-list-container .control-sm-50 {
    width: 47%;
  }
}
.control-list .control-list-header h3 i {
  margin-right: 10px;
}
.control-list .control-list-header h6 {
  font-size: 14px;
  color: #555;
}
.control-list .control-list-footer {
  width: 100%;
  display: flex;
}

/* temp_stylePlugin:src/components/control/css/metadata.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.metadata {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  font-size: 14px;
  --tw-text-opacity: 1;
  color: rgb(85 85 85 / var(--tw-text-opacity, 1));
}
.metadata label {
  margin-right: 0.5rem;
  font-weight: 700;
}
.metadata i {
  --tw-text-opacity: 1;
  color: rgb(85 85 85 / var(--tw-text-opacity, 1));
}

/* temp_stylePlugin:src/components/control/css/date-picker.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.control.control-date .react-datepicker__month-container {
  font-family: "Myriad";
}
.control.control-date input {
  width: 100%;
}
.control.control-date .react-datepicker {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}
.control.control-date .react-datepicker__header {
  border-style: none;
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(85 85 85 / var(--tw-text-opacity, 1));
}
.control.control-date .react-datepicker__current-month {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.control.control-date .react-datepicker__day-names {
  font-weight: 700;
}
.control.control-date .react-datepicker__day {
  --tw-text-opacity: 1;
  color: rgb(85 85 85 / var(--tw-text-opacity, 1));
}
.control.control-date .react-datepicker__day.react-datepicker__day--outside-month {
  --tw-text-opacity: 1;
  color: rgb(217 217 217 / var(--tw-text-opacity, 1));
}
.control.control-date .react-datepicker__day--keyboard-selected {
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(119 128 130 / var(--tw-border-opacity, 1));
  background-color: transparent;
}
.control.control-date .react-datepicker__day--selected {
  --tw-bg-opacity: 1;
  background-color: rgb(119 128 130 / var(--tw-bg-opacity, 1));
}
.control.control-date .react-datepicker__navigation-icon::before {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

/* temp_stylePlugin:src/components/control/css/tiptap.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.control-tiptap-control {
}
.control-tiptap-control .tiptap {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.control-tiptap-control .tiptap:first-child {
  margin-top: 0px;
}
.control-tiptap-control .tiptap ul {
  list-style-type: disc;
}
.control-tiptap-control .tiptap ol {
  list-style-type: decimal;
}
.control-tiptap-control .tiptap ul,
.control-tiptap-control .tiptap ol {
  margin-left: 1.25rem;
  margin-bottom: 0.5rem;
  line-height: 2rem;
}
.control-tiptap-control .tiptap pre {
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(46 43 41 / var(--tw-bg-opacity, 1));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.control-tiptap-control .tiptap pre > code {
  background-color: transparent;
  padding: 0px;
  color: inherit;
}
.control-tiptap-control .tiptap blockquote {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-left-width: 4px;
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
  padding-left: 1rem;
}
.control-tiptap-control .tiptap hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top-width: 1px;
  border-style: none;
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}
.control-tiptap-control .tiptap code {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}
.control-tiptap-control .tiptap .mention {
  --tw-bg-opacity: 1;
  background-color: rgb(119 128 130 / var(--tw-bg-opacity, 1));
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.control-tiptap-control .tiptap code,
.control-tiptap-control .tiptap .mention {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  border-radius: 2px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.control-tiptap-control .tiptap .hljs-comment,
.control-tiptap-control .tiptap .hljs-quote {
  color: #616161;
}
.control-tiptap-control .tiptap .hljs-variable,
.control-tiptap-control .tiptap .hljs-template-variable,
.control-tiptap-control .tiptap .hljs-attribute,
.control-tiptap-control .tiptap .hljs-tag,
.control-tiptap-control .tiptap .hljs-regexp,
.control-tiptap-control .tiptap .hljs-link,
.control-tiptap-control .tiptap .hljs-name,
.control-tiptap-control .tiptap .hljs-selector-id,
.control-tiptap-control .tiptap .hljs-selector-class {
  color: #f98181;
}
.control-tiptap-control .tiptap .hljs-number,
.control-tiptap-control .tiptap .hljs-meta,
.control-tiptap-control .tiptap .hljs-built_in,
.control-tiptap-control .tiptap .hljs-builtin-name,
.control-tiptap-control .tiptap .hljs-literal,
.control-tiptap-control .tiptap .hljs-type,
.control-tiptap-control .tiptap .hljs-params {
  color: #fbbc88;
}
.control-tiptap-control .tiptap .hljs-string,
.control-tiptap-control .tiptap .hljs-symbol,
.control-tiptap-control .tiptap .hljs-bullet {
  color: #b9f18d;
}
.control-tiptap-control .tiptap .hljs-title,
.control-tiptap-control .tiptap .hljs-section {
  color: #faf594;
}
.control-tiptap-control .tiptap .hljs-keyword,
.control-tiptap-control .tiptap .hljs-selector-tag {
  color: #70cff8;
}
.control-tiptap-control .tiptap .hljs-emphasis {
  font-style: italic;
}
.control-tiptap-control .tiptap .hljs-strong {
  font-weight: 700;
}

/* temp_stylePlugin:src/components/control/css/md-editor.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.control-md-editor {
}
.control-md-editor .control-md-editor-wrap {
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}
.control-md-editor .control-md-editor-wrap.readonly {
  border-style: none;
}
.control-md-editor .control-md-editor-wrap.\!readonly {
  border-style: none;
}

/* temp_stylePlugin:src/components/alert/css/alert.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.alert {
  display: flex;
  gap: 1rem;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.alert p {
  margin: 0px;
}
.alert button {
  cursor: pointer;
  padding: 0.5rem !important;
  font-size: 12px;
}
.alert .alert-text {
  display: flex;
  align-items: center;
}
.alert.alert-primary {
  --tw-border-opacity: 1;
  border-color: rgb(119 128 130 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(119 128 130 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.alert.alert-danger {
  --tw-border-opacity: 1;
  border-color: rgb(228 38 14 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(228 38 14 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.alert.alert-success {
  --tw-border-opacity: 1;
  border-color: rgb(51 119 66 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(51 119 66 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.alert.alert-default {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

/* temp_stylePlugin:src/components/layout/css/left-sidebar.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.sidebar {
  position: sticky;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  height: 100%;
  width: 370px;
  z-index: 9;
}
.left-sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 2.5rem;
}
.right-sidebar {
  top: 5rem;
  padding-top: 0px;
}
.right-sidebar.right-sidebar-absolute {
  position: fixed;
  right: 0px;
  top: 4rem;
  bottom: 0px;
  z-index: 10;
  height: unset;
  overflow: auto;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;
}
.right-sidebar.right-sidebar-absolute.hide {
  right: -400px;
}

/* temp_stylePlugin:src/components/spinner/css/main-logo.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.main-spinner-bar-loader span {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(119 128 130 / var(--tw-bg-opacity, 1)) !important;
}
.progress {
  height: 5px;
  background-color: var(--default);
}
.progress .progress-bar {
  background-color: var(--danger);
}
.main-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-loader.absolute {
  position: absolute;
}
.main-loader .progress {
  height: 3px;
}
.main-loader .progress .progress-bar {
  background-color: var(--danger);
  height: 100%;
}

/* temp_stylePlugin:src/components/alert/css/alert-list.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.alert-wrapper {
  position: fixed;
  z-index: 99999;
  display: flex;
  max-width: 20rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.alert-wrapper.alert-y-top {
  top: 2rem;
}
.alert-wrapper.alert-y-top .alert {
  animation: fadeInDown;
  animation-duration: 500ms;
}
.alert-wrapper.alert-y-center {
  top: 50%;
  transform: translateY(-50%);
}
.alert-wrapper.alert-y-bottom {
  bottom: 2rem;
}
.alert-wrapper.alert-y-bottom .alert {
  animation: fadeInUp;
  animation-duration: 500ms;
}
.alert-wrapper.alert-x-left {
  left: 2rem;
}
.alert-wrapper.alert-y-left .alert {
  animation: fadeInLeft;
  animation-duration: 500ms;
}
.alert-wrapper.alert-x-center {
  left: 50%;
  transform: translateX(-50%);
}
.alert-wrapper.alert-x-right {
  right: 2rem;
}
.alert-wrapper.alert-y-right .alert {
  animation: fadeInRight;
  animation-duration: 500ms;
}

/* temp_stylePlugin:src/main.css */
*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.visible {
  visibility: visible;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.bottom-0 {
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
.right-0 {
  right: 0px;
}
.right-4 {
  right: 1rem;
}
.right-\[-4px\] {
  right: -4px;
}
.top-0 {
  top: 0px;
}
.top-4 {
  top: 1rem;
}
.top-\[-4px\] {
  top: -4px;
}
.z-20 {
  z-index: 20;
}
.m-auto {
  margin: auto;
}
.\!mb-0 {
  margin-bottom: 0px !important;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-auto {
  margin-top: auto;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.\!hidden {
  display: none !important;
}
.hidden {
  display: none;
}
.h-10 {
  height: 2.5rem;
}
.h-12 {
  height: 3rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-28 {
  height: 7rem;
}
.h-4 {
  height: 1rem;
}
.h-40 {
  height: 10rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-7 {
  height: 1.75rem;
}
.h-8 {
  height: 2rem;
}
.h-80 {
  height: 20rem;
}
.h-9 {
  height: 2.25rem;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.w-12 {
  width: 3rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-28 {
  width: 7rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-60 {
  width: 15rem;
}
.w-7 {
  width: 1.75rem;
}
.w-8 {
  width: 2rem;
}
.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}
.w-full {
  width: 100%;
}
.w-max {
  width: -moz-max-content;
  width: max-content;
}
.max-w-3xl {
  max-width: 48rem;
}
.max-w-\[150px\] {
  max-width: 150px;
}
.max-w-lg {
  max-width: 32rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-\[0\.2\] {
  flex: 0.2;
}
.flex-\[0\.3\] {
  flex: 0.3;
}
.flex-\[0\.5\] {
  flex: 0.5;
}
.shrink-0 {
  flex-shrink: 0;
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.animate-\[200ms\] {
  animation: 200ms;
}
.cursor-pointer {
  cursor: pointer;
}
.resize {
  resize: both;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-10 {
  gap: 2.5rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-md {
  border-radius: 0.375rem;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-\[1px\] {
  border-width: 1px;
}
.border-\[2px\] {
  border-width: 2px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.\!border-primary {
  --tw-border-opacity: 1 !important;
  border-color: rgb(119 128 130 / var(--tw-border-opacity, 1)) !important;
}
.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}
.border-default {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.\!bg-body-background {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 243 243 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-default {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1)) !important;
}
.bg-\[\#0005\] {
  background-color: #0005;
}
.bg-\[\#d9d9d9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}
.bg-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(228 38 14 / var(--tw-bg-opacity, 1));
}
.bg-default {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(119 128 130 / var(--tw-bg-opacity, 1));
}
.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(225 251 50 / var(--tw-bg-opacity, 1));
}
.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(51 119 66 / var(--tw-bg-opacity, 1));
}
.bg-transparent {
  background-color: transparent;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.p-0 {
  padding: 0px;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.\!py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pl-0 {
  padding-left: 0px;
}
.pl-1 {
  padding-left: 0.25rem;
}
.text-center {
  text-align: center;
}
.text-\[12px\] {
  font-size: 12px;
}
.text-\[14px\] {
  font-size: 14px;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.text-danger {
  --tw-text-opacity: 1;
  color: rgb(228 38 14 / var(--tw-text-opacity, 1));
}
.text-default {
  --tw-text-opacity: 1;
  color: rgb(217 217 217 / var(--tw-text-opacity, 1));
}
.text-gray {
  --tw-text-opacity: 1;
  color: rgb(85 85 85 / var(--tw-text-opacity, 1));
}
.text-link {
  --tw-text-opacity: 1;
  color: rgb(50 146 250 / var(--tw-text-opacity, 1));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.\!shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow) !important;
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
}
.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    -webkit-backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.btn-love {
}
.btn-love.active {
  color: var(--danger);
}
.btn-love .animated {
}
.btn-love:hover i,
.btn-order:hover i,
.btn-post:hover i,
.btn-share:hover i,
.btn-send:hover i,
.btn-interested:hover i,
.btn-subscribe:hover i,
.btn-comment:hover i {
  animation-name: heartBeat;
  animation-duration: 1s;
}
.btn.btn-black {
  background-color: var(--black);
  border-color: var(--black);
  color: #fff;
}
.btn.btn-black:hover,
.btn.btn-black:active,
.btn.btn-black:focus,
.btn.btn-black.active {
  background-color: var(--black-hover);
}
.btn.btn-black-opacity {
  background-color: var(--black) 5;
  border: none;
}
.btn-interested.active,
.btn-subscribe.active {
  color: #007bff;
}
.btn-upload-image {
  color: #fff;
}
.btn.btn-semi-transparent {
  opacity: 0.8;
}
.btn.btn-transparent {
  background-color: transparent;
  border: 1px solid transparent;
}
.btn.btn-tiles {
  width: 30px;
  height: 30px;
  margin-top: 0;
  border: 1px solid var(--black-hover);
  padding: 0px;
}
.btn.btn-tiles.btn-tiles-xs {
  width: 15px;
  height: 15px;
}
.control-tag {
  font-size: 14px;
  padding: 5px 15px;
  padding-left: 10px;
  margin: 5px;
  background-color: var(--default);
  border: 1px solid var(--default);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  white-space: nowrap;
  position: relative;
  color: var(--black);
}
.control-tag:hover {
  opacity: 0.8;
  transition: var(--animation-fast);
}
.full-width {
  width: 100% !important;
}
.badge {
  color: #ffffff;
  font-size: 10px;
  padding: 4px 8px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 9999px;
}
.after\:-ml-4::after {
  content: var(--tw-content);
  margin-left: -1rem;
}
.after\:-mr-10::after {
  content: var(--tw-content);
  margin-right: -2.5rem;
}
.after\:mt-3::after {
  content: var(--tw-content);
  margin-top: 0.75rem;
}
.after\:inline-block::after {
  content: var(--tw-content);
  display: inline-block;
}
.after\:h-1::after {
  content: var(--tw-content);
  height: 0.25rem;
}
.after\:w-full::after {
  content: var(--tw-content);
  width: 100%;
}
.after\:border-4::after {
  content: var(--tw-content);
  border-width: 4px;
}
.after\:border-b::after {
  content: var(--tw-content);
  border-bottom-width: 1px;
}
.after\:border-primary::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(119 128 130 / var(--tw-border-opacity, 1));
}
.after\:border-secondary::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(225 251 50 / var(--tw-border-opacity, 1));
}
.after\:content-\[\'\'\]::after {
  --tw-content: "";
  content: var(--tw-content);
}
.focus-within\:outline-none:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-within\:ring-primary:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(119 128 130 / var(--tw-ring-opacity, 1));
}
.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px;
}
.hover\:text-link:hover {
  --tw-text-opacity: 1;
  color: rgb(50 146 250 / var(--tw-text-opacity, 1));
}
.disabled\:opacity-25:disabled {
  opacity: 0.25;
}

/*# sourceMappingURL=../../packages/component/dist/index.css.map */